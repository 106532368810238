import React, { useContext, useState, useEffect } from "react";
import { authContext } from "./AuthProvider";
import { NavLink } from "react-router-dom";
import { themeContext } from "./ThemeProvider";
import { getSupplier } from "../libs/sessionVariable";

import "./PageLayout.css";

export default function PageLayout({ children }) {
  const { logout, userLoggedIn, permissions } = useContext(authContext);
  const { assets } = useContext(themeContext);
  const [supplier, setSupplier] = useState("");
  const [isHighttide, setIsHighttide] = useState("");

  useEffect(() => {
    if (userLoggedIn) {
      async function fetchSupplier() {
        const defaultSupplier = await getSupplier();
        setSupplier(defaultSupplier);
      }
      fetchSupplier();
    }
  }, [userLoggedIn]);

  if (!userLoggedIn) {
    return children;
  }

  return (
    <div className={`page-layout__container ${assets.rootClass}`}>
      <div className="page-layout__side-bar">
        <img
          className="page-layout__logo"
          src={assets.logo}
          alt=""
          style={assets.logoNavStyle}
        />
        <NavLink
          className="page-layout__side-bar-item"
          activeClassName="activated"
          to="/availability"
          isActive={(match, location) => {
            if (match) {
              return true;
            }
          }}
        >
          Check Availability
        </NavLink>
        {permissions.ethernetAccess && (
          <NavLink
            className="page-layout__side-bar-item"
            activeClassName="activated"
            to="/ethernetquoting"
            isActive={(match, location) => {
              if (match) {
                return true;
              }
            }}
          >
            Ethernet Quoting
          </NavLink>
        )}

        {permissions.orderAccess && (
          <NavLink
            className="page-layout__side-bar-item"
            activeClassName="activated"
            to="/SearchOrders"
            isActive={(match, location) => {
              if (match) {
                return true;
              }
            }}
          >
            Orders
          </NavLink>
        )}

        {permissions.servicesAccess && (
          <NavLink
            className="page-layout__side-bar-item"
            activeClassName="activated"
            to="/Services"
            isActive={(match, location) => {
              if (match) {
                return true;
              }
            }}
          >
            Services
          </NavLink>
        )}

        {supplier === "Hightide" && (
          <NavLink
            className="page-layout__side-bar-item"
            activeClassName="activated"
            to="/Notifications/OrderNotifications"
            isActive={(match, location) => {
              if (match) {
                return true;
              }
            }}
          >
            Notifications
          </NavLink>
        )}

        {supplier === "Fibre1" && (
          <a
            className="page-layout__side-bar-item"
            href="https://fibre1.custservice.co/user/login?ReturnUrl=dashboard"
            target="_blank"
            rel="noopener noreferrer"
          >
            F1CRM/ IOT
          </a>
        )}

        <div className="page-layout__side-bar-spacer" />
        <div className="page-layout__side-bar-item" onClick={logout}>
          <span>Logout</span>
        </div>
      </div>
      <div className="page-layout__content">{children}</div>
    </div>
  );
}
