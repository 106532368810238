import { BASE_URL } from "../environment";
import makeRequest from "../makeRequest";

export async function searchOrders(searchTerm) {
  var orders = await searchZenOrders(searchTerm);
  console.log(orders);
  return orders;
}

async function searchZenOrders(searchTerm) {
    const encodedSearchTerm = encodeURIComponent(searchTerm || "");
    const results = await makeRequest(`${BASE_URL}/zensearchorders?searchTerm=${encodedSearchTerm}&pageSize=10000`);
  
    debugger;
    if (!results || !results.order || !Array.isArray(results.order.fulfilments) || results.order.fulfilments.length === 0) {
      return { orders: [], giganetUpdates: {}, zenUpdates: {} };
    }

  // Safely map notifications, ensuring we always have an object to work with
  const zenNotifications = mapZenNotifications(results.notificationsByOrder || {});
  const giganetUpdates = mapGiganetNotifications(results.updatesByOrder || {});
  const mappedResults = (results.order?.fulfilments || []).map(order => mapZenOrder(order, giganetUpdates)).filter(order => order !== null);

  // Sort results safely
  mappedResults.sort((a, b) => a.orderDate < b.orderDate ? 1 : -1);

  return { 
    orders: mappedResults,
    giganetUpdates: giganetUpdates,
    zenUpdates: zenNotifications
  };
}

const mapZenNotifications = (notificationsByOrder) => {
  const mappedNotifications = {};
  for (const zenRef in notificationsByOrder) {
    // Ensure the value is an array before mapping
    if (Array.isArray(notificationsByOrder[zenRef])) {
      mappedNotifications[zenRef] = notificationsByOrder[zenRef].map(notification => {
        // Mapping logic here, if needed. For now, it just returns the notification as is.
        return notification;
      });
    } else {
      mappedNotifications[zenRef] = []; // Default to an empty array if not an array
    }
  }
  return mappedNotifications;
}

const mapGiganetNotifications = (updatesByOrder) => {
  const mappedNotifications = {};
  for (const zenRef in updatesByOrder) {
    if (updatesByOrder.hasOwnProperty(zenRef) && Array.isArray(updatesByOrder[zenRef])) {
      const upperZenRef = zenRef.toUpperCase();
      mappedNotifications[upperZenRef] = updatesByOrder[zenRef].map(notification => {
        // Mapping logic here, if needed
        return notification;
      });
    } else {
      // Default to an empty array if property does not exist or is not an array
      mappedNotifications[zenRef.toUpperCase()] = [];
    }
  }
  return mappedNotifications;
};

const mapZenOrder = (order, giganetUpdates) => {
  if (!order) return null; // Handle case when order is null or undefined

  let status = order.fulfilmentStatus;
  const upperZenRef = order.zenReference?.toUpperCase();

  // Check if updates exist and are an array before attempting to use them
  if (upperZenRef && giganetUpdates[upperZenRef] && Array.isArray(giganetUpdates[upperZenRef]) && giganetUpdates[upperZenRef].length > 0) {
    const updates = giganetUpdates[upperZenRef];
    const latestUpdate = updates.reduce((latest, current) => 
      new Date(latest.status_timestamp) > new Date(current.status_timestamp) ? latest : current, updates[0]);

    status = latestUpdate.state;
  }

  return {
    ...order,
    reference: order.zenReference,
    status: status,
    committedDate: order.providerPromisedCompletionDate,
    completedDate: order.providerCompletionDate,
    ...order.installationAddress
  };
}
