import makeRequest from '../makeRequest';
import { BASE_URL } from '../environment';
import {getSupplier, getSuppliers} from '../sessionVariable';
import { keyBy } from 'lodash';

function createRequestBody(phoneNumber, address, uprnRequest) {
  if (!phoneNumber && !address) {
    throw new Error('No phone number or address provided');
  }

  if (phoneNumber) {
    return JSON.stringify({ phoneNumber });
  }

  const { addressReference, postCode } = address;
  const { addressReferenceNumber, districtCode, uprn } = addressReference;

  if(uprnRequest){
    return  JSON.stringify({address_key:uprn,postcode:postCode,productTypes:["fttp"],promocode:""});
  }

  return JSON.stringify({
    postCode,
    goldAddressKeyAvailabilityRequest: {
      addressReferenceNumber,
      districtCode
    },
    uprn
  })
}

function isTalkTalk(response) {
  const { FTTCAvailability } = response.FTTCAvailability ? response.FTTCAvailability[0] || {} : {};

  if(response && response.FTTCAvailability && response.FTTCAvailability.length <= 0){
    return false;
  }

  const {Errors} = response.Status;

  if(Errors && Errors?.length > 0){
    return false;
  }

  if (FTTCAvailability && (FTTCAvailability.FTTCAvailableMI === "Yes" || FTTCAvailability.FTTCAvailableSI === "Yes")) {
    return true;
  }
  return false;
}

function getTalkTalkFttc(response) {
  const { FTTCAvailability } = response.FTTCAvailability[0] || {};
  return FTTCAvailability;
}

function transformTalkTalkResponse(response, zenLineChars, request, supplier) {
  const errorMessage = response.errorMessage;

  if (errorMessage === '[Bad Request] invalid phone number') {
    throw new Error('No Results');
  }

  if (errorMessage) {
    throw new Error('Server Error');
  }

  if (!isTalkTalk(response)) {
    return {};
  }

  const products = transformTTBBroadbandGroupProducts(response, zenLineChars, request, supplier)

 return {
    FTTC: {
      broadbandType: 'FTTC',
      provider: supplier,
      onNet: getTalkTalkFttc(response).FTTCOpenreachAvailable === 'Y',
      products: products
    }
  };
}

function transformZenResponse(response, request, supplier) {
  const errorMessage = response.errorMessage;

  debugger;

  if (errorMessage === '[Bad Request] invalid phone number') {
    throw new Error('No Results');
  }

  if (errorMessage) {
    throw new Error('Server Error');
  }

  if (!response) {
    throw new Error('No Results');
  }

  // if (!response.availabilityReference) {
  //   throw new Error('No Results');
  // }

  // if (response?.availabilityInformation?.messages?.length > 0) {
  //   throw new Error('No Results');
  // }

  if (!response?.broadbandGroups || response.broadbandGroups.length < 1) {
    throw new Error('No Results');
  }

  if (response.availabilityReference === 'n/a') return {};

  const broadbandGroups = response.broadbandGroups.map(group => {
    const { broadbandType, exchangeTechnology } = group;
    const product = transformZenBroadbandGroupProducts(group, response, request, supplier);

      return {
        broadbandType,
        provider: supplier,
        onNet: exchangeTechnology.network === 'NetworkOnNet',
        products: product,
      };
  });

  return keyBy(broadbandGroups, 'broadbandType');
}

function transformZenBroadbandGroupProducts(group, response, request, supplier) {

  // REMOVE PRODUCTS WITH NO PROVISION TYPE AND LOW BANDWIDTHS
  for(var i = 0; i < group?.products.length; i++) {                      
    if (group.products[i].productCode === "SOGEA0505" || group.products[i].productCode === "SOGEA4010"
        || group.products[i].productCode === "SOGEA5510"
        || group.products[i].productCode === "FTTP22020"
        || group.products[i].productCode === "FTTP0505"
        )
        {
          group.products.splice(i, 1)
        i--
    }
}

  return group.products.map(product => ({
    broadbandType: group.broadbandType,
    marketLocation: product.marketLocation,
    available: product.isOrderable,
    productName: product.productName,
    productCode: product.productCode,
    provider: supplier,
    supplierProductCode: product?.SupplierProductCode,
    orderDetails: getOrderDetails(product, response, request)
  }));
}

function transformTTBBroadbandGroupProducts(ttbResponse, zenLineChars, request, supplier) {

  const products = [
    {
      broadbandType: 'FTTC',
      marketLocation: 'Unavailable',
      available: true,
      productName: 'FTTC 40/10',
      productCode: 'FTTC_WLR_0003',
      provider: supplier,
    },

    {
      broadbandType: 'FTTC',
      marketLocation: 'Unavailable',
      available: true,
      productName: 'FTTC 80/20',
      productCode: 'FTTC_WLR_0011',
      provider: supplier,
    }]

  return products.map(product => ({
    broadbandType: product.broadbandType,
    marketLocation: product.marketLocation,
    available: true,
    productName: product.productName,
    productCode: product.productCode,
    provider: supplier,
    orderDetails: getTTBOrderDetails(ttbResponse, zenLineChars, request)
  }));
}

function getTTBOrderDetails(response, zenLineChars, request) {
  const { ExistingServicesDetails } = response?.FTTCAvailability[0];
  const provisionType = getTalkTalkProvisionType(request, response);

  return {
    ExistingServicesDetails,
    request,
    zenLineChars,
    provisionType
  }
}

function getOrderDetails(product, response, request) {
  console.log('product :', product)
  console.log('response: ', response);
  console.log('request:', request);

  const { accessLines, ontDetails, availabilityReference, giganetQuoteUlid } = response;
  const { minimumActivationDate, productOptions, provisionType } = product;
  const { goldAddressKeyAvailabilityRequest } = request;
  let reference = '';
  let serialNumber = '';
  let location = '';
  let ports = {};

  for (var i = 0; i < ontDetails?.length; i++) {
    reference = ontDetails[i].Reference || {};
    serialNumber = ontDetails[i].SerialNumber || {};
    location = ontDetails[i].Location || {};
    ports = ontDetails[i].Ports || {};
  }

  if (accessLines && accessLines.length > 0 && accessLines[0].lineType != "StoppedLine" ) {
    accessLines[0].lineNumber = !request.phoneNumber ? '' : request.phoneNumber;
  }

  const { fttp, fttc, sogea, adsl2Plus, adsl2PlusAnnexM, lineCharacteristics, gFast, FttpSymmetrical } = response.lineDetails;
  const requestPostCode = !request?.postCode ? '' : request.postCode;
  // lineCharacteristics.btOpenreachPostCode = 'OL14 6BA';
    return {
      ports,
      productOptions,
      minimumActivationDate,
      accessLines,
      provision: {
        reference,
        serialNumber,
        provisionType,
        position: location?.position,
        room: location?.room
      },
      lineCharacteristics,
      requestPostCode,
      goldAddressKeyAvailabilityRequest,
      request,
      availabilityReference,
      giganetQuoteUlid
    }
}

function buildTalkTalkLineChars(talkTalkResponse, zenResponse) {
  const postCode = talkTalkResponse?.Postcode || ""; // Default to empty string if undefined
  const pcpId = talkTalkResponse?.FTTCAvailability[0]?.FTTCAvailability?.PCPID || ""; // Default to empty string if undefined
  
  return {
    btOpenreachPostCode: postCode,
    btWholesalePostCode: postCode,
    pcpId: pcpId,
    lineLength: 'Unknown',
    ...(zenResponse.lineCharacteristics || {}),
  };
}

function buildTalkTalkLineDetails(talkTalkResponse, request) {
  const { BandwidthMeasure, RangeABottomDownstreamData, RangeABottomUpstream, RangeAMinThresholdDownstream, RangeATopDownstream, RangeATopUpstream, RangeBBottomDownstream, RangeBBottomUpstream, RangeBMinThresholdDownstream, RangeBTopDownstream, RangeBTopUpstream } = getTalkTalkFttc(talkTalkResponse)?.EstimatedSyncSpeed;
  const { AccessLineID } = !talkTalkResponse && !talkTalkResponse.FTTCAvailability && !talkTalkResponse?.FTTCAvailability?.ExistingServicesDetails ? talkTalkResponse?.FTTCAvailability[0].ExistingServicesDetails[0] : {};

  let rangeADownstreamTop = RangeATopDownstream * 1000;
  let rangeADownstreamBottom = RangeABottomDownstreamData * 1000;
  let rangeATopUpstream = RangeATopUpstream * 1000;
  let rangeAUpstreamBottom = RangeABottomUpstream * 1000;

  let rangeBDownstreamTop = RangeBTopDownstream * 1000;
  let rangeBDownstreamBottom = RangeBBottomDownstream * 1000;
  let rangeBUpstreamTop = RangeBTopUpstream * 1000;
  let rangeBUpstreamBottom = RangeBBottomUpstream * 1000;

  const ragDescription = getTalkTalkFttc(talkTalkResponse)?.FTTCAvailableMI === "Yes" ? "Green; very likely service can be provided" : {};
  const rag = getTalkTalkFttc(talkTalkResponse)?.FTTCAvailableMI === "Yes" ? "Green" : {};
  const fttcUnAvailableMessage = {};
  const mdfSiteId = getTalkTalkFttc(talkTalkResponse).ExchangeDetails[0].OHPExchangeCode || {};
  const mdfSiteName = getTalkTalkFttc(talkTalkResponse).ExchangeDetails[0].OHPExchangeName || {};
  const fttcExchangeName = mdfSiteName;
  const fttcExchangeCode = mdfSiteId;
  const availabilityDescription = getTalkTalkFttc(talkTalkResponse).FTTCAvailableMI === "Yes" ? "FTTC available - both network infrastructure and bandwidth are available." : {};
  const bandwidthMeasure = BandwidthMeasure || {};
  const rangeADownstreamTopSpeed = getTalkTalkFttc(talkTalkResponse)?.FTTCAvailableMI === "Yes" ? RangeATopDownstream + "Mbit/s" : {};
  const rangeADownstreamBottomSpeed = getTalkTalkFttc(talkTalkResponse)?.FTTCAvailableMI === "Yes" ? RangeABottomDownstreamData + "Mbit/s" : {};
  const rangeAUpstreamTopSpeed = getTalkTalkFttc(talkTalkResponse)?.FTTCAvailableMI === "Yes" ? RangeATopUpstream + "Mbit/s" : {};
  const rangeAUpstreamBottomSpeed = getTalkTalkFttc(talkTalkResponse)?.FTTCAvailableMI === "Yes" ? RangeABottomUpstream + "Mbit/s" : {};
  const rangeBDownstreamTopSpeed = getTalkTalkFttc(talkTalkResponse)?.FTTCAvailableMI === "Yes" ? RangeBTopDownstream + "Mbit/s" : {};
  const rangeBDownstreamBottomSpeed = getTalkTalkFttc(talkTalkResponse)?.FTTCAvailableMI === "Yes" ? RangeBBottomDownstream + "Mbit/s" : {};
  const rangeBUpstreamTopSpeed = getTalkTalkFttc(talkTalkResponse)?.FTTCAvailableMI === "Yes" ? RangeBTopUpstream + "Mbit/s" : {};
  const rangeBUpstreamBottomSpeed = getTalkTalkFttc(talkTalkResponse)?.FTTCAvailableMI === "Yes" ? RangeBBottomUpstream + "Mbit/s" : {};
  const readyDate = {};
  const provisioningType = getTalkTalkProvisionType(request, talkTalkResponse);
  const rangeADownstreamTopSpeedValue = rangeADownstreamTop.toFixed(0) || {};
  const rangeAUpstreamTopSpeedValue = rangeATopUpstream.toFixed(0) || {};
  const rangeADownstreamBottomSpeedValue = rangeADownstreamBottom.toFixed(0) || {};
  const rangeAUpstreamBottomSpeedValue = rangeAUpstreamBottom.toFixed(0) || {};
  const rangeBDownstreamTopSpeedValue = rangeBDownstreamTop.toFixed(0) || {};
  const rangeBDownstreamBottomSpeedValue = rangeBDownstreamBottom.toFixed(0) || {};
  const rangeBUpstreamTopSpeedValue = rangeBUpstreamTop.toFixed(0) || {};
  const rangeBUpstreamBottomSpeedValue = rangeBUpstreamBottom.toFixed(0) || {};

  return {
    "ragDescription": ragDescription,
    "rag": rag,
    "fttcUnAvailableMessage": fttcUnAvailableMessage,
    "mdfSiteId": mdfSiteId,
    "fttcExchangeName": fttcExchangeName,
    "availabilityDescription": availabilityDescription,
    "bandwidthMeasure": bandwidthMeasure,
    "rangeADownstreamTopSpeed": rangeADownstreamTopSpeed,
    "rangeADownstreamBottomSpeed": rangeADownstreamBottomSpeed,
    "rangeAUpstreamTopSpeed": rangeAUpstreamTopSpeed,
    "rangeAUpstreamBottomSpeed": rangeAUpstreamBottomSpeed,
    "rangeBDownstreamTopSpeed": rangeBDownstreamTopSpeed,
    "rangeBDownstreamBottomSpeed": rangeBDownstreamBottomSpeed,
    "rangeBUpstreamTopSpeed": rangeBUpstreamTopSpeed,
    "rangeBUpstreamBottomSpeed": rangeBUpstreamBottomSpeed,
    "readyDate": readyDate,
    "provisioningType": provisioningType,
    "rangeADownstreamTopSpeedValue": rangeADownstreamTopSpeedValue,
    "rangeAUpstreamTopSpeedValue": rangeAUpstreamTopSpeedValue,
    "rangeADownstreamBottomSpeedValue": rangeADownstreamBottomSpeedValue,
    "rangeAUpstreamBottomSpeedValue": rangeAUpstreamBottomSpeedValue,
    "rangeBDownstreamTopSpeedValue": rangeBDownstreamTopSpeedValue,
    "rangeBDownstreamBottomSpeedValue": rangeBDownstreamBottomSpeedValue,
    "rangeBUpstreamTopSpeedValue": rangeBUpstreamTopSpeedValue,
    "rangeBUpstreamBottomSpeedValue": rangeBUpstreamBottomSpeedValue,
    "fttcExchangeCode": fttcExchangeCode
  };
}

function getTalkTalkProvisionType(request, talktalkResponse) {
  if (typeof (talktalkResponse?.FTTCAvailability[0]?.ExistingServicesDetails) === "undefined" && request?.phoneNumber
    || typeof (talktalkResponse?.FTTCAvailability[0].ExistingServicesDetails) !== "undefined" && talktalkResponse?.FTTCAvailability[0]?.ExistingServicesDetails.length > -1 && !talktalkResponse?.FTTCAvailability[0]?.ExistingServicesDetails[0]?.AccessLineID && request?.phoneNumber) {
    return 'NewProvide';
  }

  else if (typeof (talktalkResponse?.FTTCAvailability[0]?.ExistingServicesDetails) !== "undefined" && talktalkResponse?.FTTCAvailability[0]?.ExistingServicesDetails.length > -1 && talktalkResponse?.FTTCAvailability[0]?.ExistingServicesDetails[0]?.AccessLineID && request?.phoneNumber) {
    return 'Migration';
  }

  else {
    return "SimProvide";
  }
}

export default async function availablityCheckRequest(phoneNumber, address) {
  // Giganet 
  debugger;
  let body = createRequestBody(phoneNumber, address, true);
  const giganetRequest = await isOnboarded('Giganet') ? makeRequest(`${BASE_URL}/giganet-availability`, { method: 'POST', body }) : new Promise((x) => x({}));

  // TTB and Zen
  body = createRequestBody(phoneNumber, address, false);

  const { addressReference } = address;
  const { districtCode } = addressReference;

  // Only make the Zen request if districtCode is present
  const zenRequest = await (isOnboarded('Zen') && districtCode) 
    ? makeRequest(`${BASE_URL}/availability`, { method: 'POST', body }) 
    : new Promise((x) => x({ availabilityReference: 'n/a', lineDetails: {} }));

  const talktalkRequest = await isOnboarded('TalkTalk') 
    ? makeRequest(`${BASE_URL}/talktalkAvailability`, { method: 'POST', body }) 
    : new Promise((x) => x({ FTTCAvailability: [] }));

  let [talktalkResponse, zenResponse, giganetResponse] = await Promise.all([talktalkRequest, zenRequest, giganetRequest]);

  const zenSupplier = await getSupplier("Zen Internet");
  const ttbSupplier = await getSupplier("TalkTalk Business");
  const giganetSupplier = await getSupplier("Giganet");

  debugger;

  // Giganet handling logic

  // Has Zen Products
  if (await isOnboarded('Giganet') && typeof zenResponse === 'object' && giganetResponse !== "" && giganetResponse?.broadbandGroups?.length > 0) {
    // Append giganetResponse broadbandGroups to zenResponse
    if (!Array.isArray(zenResponse.broadbandGroups)) {
      zenResponse.broadbandGroups = [];
    }
    if (!zenResponse.lineDetails) {
      zenResponse.lineDetails = {};
    }
    Array.prototype.push.apply(zenResponse.broadbandGroups, giganetResponse.broadbandGroups);
    zenResponse.lineDetails.FttpSymmetrical = giganetResponse.lineDetails.FttpSymmetrical;
    zenResponse.giganetQuoteUlid = giganetResponse.giganetQuoteUlid;
  }

  // Zen endpoint has error but there are Giganet products
  if (await isOnboarded('Giganet') && typeof zenResponse !== 'object' && giganetResponse !== "" && giganetResponse?.broadbandGroups.length > 0) {
    zenResponse = giganetResponse;
    if (zenResponse?.lineDetails && giganetResponse?.lineDetails) {
      zenResponse.lineDetails.FttpSymmetrical = giganetResponse.lineDetails.FttpSymmetrical;
      zenResponse.giganetQuoteUlid = giganetResponse.giganetQuoteUlid;
    }
  }

  const zenResults = zenResponse.broadbandGroups != null ? transformZenResponse(zenResponse, JSON.parse(body), zenSupplier) : {};
  const talktalkResults = transformTalkTalkResponse(talktalkResponse, zenResponse?.lineDetails?.lineCharacteristics, JSON.parse(body), ttbSupplier);

  const broadbandGroups = Object.values({
    ...zenResults,
    ...talktalkResults
  });

  if (isTalkTalk(talktalkResponse)) {
    zenResponse.lineDetails.fttc = buildTalkTalkLineDetails(talktalkResponse, body);
    zenResponse.lineDetails.lineCharacteristics = buildTalkTalkLineChars(talktalkResponse, zenResponse);
  }
  
  return {
    broadbandGroups,
    lineDetails: zenResponse.lineDetails
  };
}

async function isOnboarded(supplier) {
  const suppliers = await getSuppliers();
  return suppliers[supplier] === 1
}