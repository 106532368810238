import React from 'react';

export default class NotificationMessage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
    };
  }

  render() {
    const { message } = this.props;
    const { isExpanded } = this.state;

    if (message.length < 160) {
      return <div className="formatText">{message}</div>;
    }

    return (
      <div>
        <div className="formatText">
          {isExpanded ? message : `${message.substring(0, 150)}...`}
        </div>
        {message.length >= 160 && (
          <button 
            onClick={() => this.setState({ isExpanded: !isExpanded })} 
            style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer', padding: 0 }}
          >
            {isExpanded ? 'Show Less' : 'Read More'}
          </button>
        )}
      </div>
    );
  }
}
