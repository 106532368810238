import React from 'react';
import Panel from '../Panel';
import './LineDetails.css';

export default function LineDetails({ lineDetails }) {
  const { 
    fttp, 
    fttc, 
    sogea, 
    adsl2Plus, 
    adsl2PlusAnnexM, 
    lineCharacteristics = {}, // Ensure lineCharacteristics is always an object
    gFast, 
    FttpSymmetrical 
  } = lineDetails || {}; // Default lineDetails to an empty object

  return (
    <Panel header='Additional line details'>
      <section className='line-details__section'>
        <h2 className='line-details__section-header'>Line Characteristics</h2>
        <div className='line-details__table'>
          <span>BT Openreach post code</span>
          <span>{lineCharacteristics?.btOpenreachPostCode || ''}</span>
          <span>BT Wholesale post code</span>
          <span>{lineCharacteristics?.btWholesalePostCode || ''}</span>
          <span>PCP Id</span>
          <span>{lineCharacteristics?.pcpId || ''}</span>
          <span>Line length (in meters)</span>
          <span>{lineCharacteristics?.lineLength || ''}</span>
        </div>
      </section>

      {fttp && (
        <section className='line-details__section'>
          <h2 className='line-details__section-header'>FTTP</h2>
          <div className='line-details__table'>
            <span>RAG description</span>
            <span>{fttp?.ragDescription || ''}</span>
            {fttp?.fttpUnAvailableMessage && (
              <>
                <span>Availability description</span>
                <span>{fttp?.fttpUnAvailableMessage || ''}</span>
              </>
            )}
            <span>Max downstream speed</span>
            <span>{fttp?.maxDownstreamSpeed || ''}</span>
            <span>Max upstream speed</span>
            <span>{fttp?.maxUpstreamSpeed || ''}</span>
            <span>Exchange status description</span>
            <span>{fttp?.exchangeStatusDescription || ''}</span>
            <span>Exchange name</span>
            <span>{fttp?.fttpExchangeName || ''}</span>
            <span>Exchange code</span>
            <span>{fttp?.fttpExchangeCode || ''}</span>
          </div>
        </section>
      )}

      {FttpSymmetrical && (
        <section className='line-details__section'>
          <h2 className='line-details__section-header'>FTTP SYMMETRICAL</h2>
          <div className='line-details__table'>
            <span>RAG description</span>
            <span>{FttpSymmetrical?.ragDescription || ''}</span>
            {FttpSymmetrical?.fttpUnAvailableMessage && (
              <>
                <span>Availability description</span>
                <span>{FttpSymmetrical?.fttpUnAvailableMessage || ''}</span>
              </>
            )}
            <span>Max downstream speed</span>
            <span>{`${FttpSymmetrical?.maxDownstreamSpeed || ''} Mbit/s`}</span>
            <span>Max upstream speed</span>
            <span>{`${FttpSymmetrical?.maxUpstreamSpeed || ''} Mbit/s`}</span>
            <span>Exchange status description</span>
            <span>{FttpSymmetrical?.exchangeStatusDescription || ''}</span>
            <span>Exchange name</span>
            <span>{FttpSymmetrical?.fttpExchangeName || ''}</span>
            <span>Exchange code</span>
            <span>{FttpSymmetrical?.fttpExchangeCode || ''}</span>
          </div>
        </section>
      )}

      {fttc && (
        <section className='line-details__section'>
          <h2 className='line-details__section-header'>FTTC</h2>
          <div className='line-details__table'>
            <span>RAG description</span>
            <span>{fttc?.ragDescription || ''}</span>
            <span>Availability description</span>
            <span>{fttc?.availabilityDescription || fttc?.fttcUnAvailableMessage || ''}</span>
            <span>Bandwidth measure</span>
            <span>{fttc?.bandwidthMeasure || ''}</span>
            <span>Range A downstream top speed</span>
            <span>{fttc?.rangeADownstreamTopSpeed || ''}</span>
            <span>Range A downstream bottom speed</span>
            <span>{fttc?.rangeADownstreamBottomSpeed || ''}</span>
            <span>Range A upstream top speed</span>
            <span>{fttc?.rangeAUpstreamTopSpeed || ''}</span>
            <span>Range A upstream bottom speed</span>
            <span>{fttc?.rangeAUpstreamBottomSpeed || ''}</span>
            <span>Range B downstream top speed</span>
            <span>{fttc?.rangeBDownstreamTopSpeed || ''}</span>
            <span>Range B downstream bottom speed</span>
            <span>{fttc?.rangeBUpstreamBottomSpeed || ''}</span>
          </div>
        </section>
      )}

      {sogea && (
        <section className='line-details__section'>
          <h2 className='line-details__section-header'>SOGEA</h2>
          <div className='line-details__table'>
            <span>RAG description</span>
            <span>{sogea?.ragDescription || ''}</span>
            <span>Availability description</span>
            <span>{sogea?.availabilityDescription || sogea?.unAvailableMessage || ''}</span>
            <span>Bandwidth measure</span>
            <span>{sogea?.bandwidthMeasure || ''}</span>
            <span>Range A downstream top speed</span>
            <span>{sogea?.rangeADownstreamTopSpeed || ''}</span>
            <span>Range A downstream bottom speed</span>
            <span>{sogea?.rangeADownstreamBottomSpeed || ''}</span>
            <span>Range A upstream top speed</span>
            <span>{sogea?.rangeAUpstreamTopSpeed || ''}</span>
            <span>Range A upstream bottom speed</span>
            <span>{sogea?.rangeAUpstreamBottomSpeed || ''}</span>
            <span>Range B downstream top speed</span>
            <span>{sogea?.rangeBDownstreamTopSpeed || ''}</span>
            <span>Range B downstream bottom speed</span>
            <span>{sogea?.rangeBUpstreamBottomSpeed || ''}</span>
          </div>
        </section>
      )}

      {adsl2Plus && (
        <section className='line-details__section'>
          <h2 className='line-details__section-header'>ADSL2+</h2>
          <div className='line-details__table'>
            <span>RAG description</span>
            <span>{adsl2Plus?.ragDescription || ''}</span>
            <span>Reason code description</span>
            <span>{adsl2Plus?.reasonCodeDescription || ''}</span>
            <span>Speed</span>
            <span>{adsl2Plus?.speed || ''}</span>
            <span>Speed range min</span>
            <span>{adsl2Plus?.speedRangeMin || ''}</span>
            <span>Speed range max</span>
            <span>{adsl2Plus?.speedRangeMax || ''}</span>
            <span>Exchange status description</span>
            <span>{adsl2Plus?.exchangeStatusDescription || ''}</span>
            <span>Exchange name</span>
            <span>{adsl2Plus?.mdfSiteName || ''}</span>
            <span>Exchange code</span>
            <span>{adsl2Plus?.mdfSiteId || ''}</span>
          </div>
        </section>
      )}

      {adsl2PlusAnnexM && (
        <section className='line-details__section'>
          <h2 className='line-details__section-header'>ADSL2+ (Annex M)</h2>
          <div className='line-details__table'>
            <span>RAG description</span>
            <span>{adsl2PlusAnnexM?.ragDescription || ''}</span>
            <span>Download speed</span>
            <span>{adsl2PlusAnnexM?.downloadSpeed || ''}</span>
            <span>Upload speed</span>
            <span>{adsl2PlusAnnexM?.uploadSpeed || ''}</span>
            <span>Min speed</span>
            <span>{adsl2PlusAnnexM?.minSpeed || ''}</span>
            <span>Max speed</span>
            <span>{adsl2PlusAnnexM?.maxSpeed || ''}</span>
            <span>Exchange name</span>
            <span>{adsl2PlusAnnexM?.mdfSiteName || ''}</span>
            <span>Exchange code</span>
            <span>{adsl2PlusAnnexM?.mdfSiteId || ''}</span>
          </div>
        </section>
      )}

      {gFast && (
        <section className='line-details__section'>
          <h2 className='line-details__section-header'>GFast</h2>
          <div className='line-details__table'>
            <span>RAG description</span>
            <span>{gFast?.ragDescription || ''}</span>
            <span>Availability description</span>
            <span>{gFast?.availabilityDescription || gFast?.gFastUnAvailableMessage || ''}</span>
            <span>Bandwidth measure</span>
            <span>{gFast?.bandwidthMeasure || ''}</span>
            <span>Range A downstream top speed</span>
            <span>{gFast?.rangeADownstreamTopSpeed || ''}</span>
            <span>Range A downstream bottom speed</span>
            <span>{gFast?.rangeADownstreamBottomSpeed || ''}</span>
            <span>Range A upstream top speed</span>
            <span>{gFast?.rangeAUpstreamTopSpeed || ''}</span>
            <span>Range A upstream bottom speed</span>
            <span>Range B downstream top speed</span>
            <span>{gFast?.rangeBDownstreamTopSpeed || ''}</span>
            <span>Range B downstream bottom speed</span>
            <span>Range B upstream bottom speed</span>
          </div>
        </section>
      )}
    </Panel>
  );
}
